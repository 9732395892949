<template>
    <div class="">
        <div class="inner-header  ">
            <nav class="navbar fixed-top bg-white animate__animated animate__slideInRight">
                <a @click="$router.replace('/')" class="back-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.368" height="16.365" viewBox="0 0 17.368 16.365">
                        <g id="Group_38360" data-name="Group 38360" transform="translate(-27.632 -34.814)" opacity="1">
                            <line id="Line_124" data-name="Line 124" x2="16" transform="translate(29 43)" fill="none"
                                stroke="#000" stroke-width="2" />
                            <path id="Path_51451" data-name="Path 51451" d="M.033,0l-7,7.425L.033,15"
                                transform="translate(35.967 35.5)" fill="none" stroke="#000" stroke-width="2" />
                        </g>
                    </svg>
                </a>
                <a href="javascript:void(0);" class="link-title center">{{ $route.query.tabStatus ? 'Onboard CP' : 'New Entry' }}</a>
            </nav>
        </div>
        <div class="inner-page bg-white animate__animated animate__slideInRight">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card details-card form-card">
                            <div class="card-body p-0 mt-5">
                                <div class="row">
                                  <div class="col-12">
                                    <b-form @submit.prevent="AddNewbroker">
                                      <b-form-group>
                                        <label>Broker Name<span class="text-danger">*</span></label>
                                        <b-form-input type="text" v-model="Addbroker.name" required></b-form-input>
                                    </b-form-group>
                                      <b-form-group>
                                        <label>Email<span class="text-danger">*</span></label>
                                        <b-form-input type="email" v-model="Addbroker.email" required></b-form-input>
                                    </b-form-group>
                                      <div class="iso-code-section">
                                        <b-form-group class="filterPanel">
                                          <label>ISO Code<span class="text-danger">*</span></label>
                                            <multiselect v-model="Addbroker.iso_code" placeholder="Select Iso Code" :searchable="true" label="text" track-by="value" :options="countryList" deselect-label="Can't remove this value" :taggable="true" :allow-empty="false"></multiselect>
                                        </b-form-group>
                                        <b-form-group class="city-id-section">
                                          <label>Contact Number<span class="text-danger">*</span></label>
                                          <b-form-input type="number" v-model="Addbroker.contact_number" required></b-form-input>
                                        </b-form-group>
                                      </div>
                                      <b-form-group>
                                        <label>Firm Name<span class="text-danger">*</span></label>
                                        <b-form-input type="text" v-model="Addbroker.firm_name" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group>
                                      <label>DOB</label>
                                      <b-form-input type="date" v-model="Addbroker.dob"></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if="$route.query.tabStatus">
                                      <label>Address<span class="text-danger">*</span></label>
                                        <b-form-textarea v-model="Addbroker.address" required rows="3" max-rows="6">
                                        </b-form-textarea>
                                    </b-form-group>
                                      <b-form-group  class="filterPanel city-id-section">
                                        <label>City<span class="text-danger">*</span></label>
                                          <multiselect v-model="Addbroker.city_id" placeholder="Select City" @input="fetchRegion(Addbroker.city_id)" :searchable="true" label="text" track-by="value" :options="cityList" :taggable="true" ref="city_select"></multiselect>
                                      </b-form-group>
                                    <b-form-group class="filterPanel mt-4">
                                      <label>Region<span class="text-danger">*</span></label>
                                        <b-form-group >
                                          <multiselect v-model="Addbroker.region" placeholder="Select Region" @input="fetchLocality()" :searchable="true" label="text" track-by="value" ref="region_select" :options="regionList" :multiple="true" :taggable="true"></multiselect>
                                        </b-form-group>
                                    </b-form-group>
                                    <b-form-group class="filterPanel">
                                      <label>Locality<span class="text-danger">*</span></label>
                                        <multiselect v-model="Addbroker.primary_locality_id" placeholder="Select Locality" @input="fetchProject(Addbroker.primary_locality_id)" :searchable="true" label="text" track-by="value" :options="localityList" :taggable="true" ref="locality_select"></multiselect>
                                    </b-form-group>
                                    <b-form-group class="filterPanel mt-4">
                                      <label>Pincode <span class="text-danger">*</span></label>
                                      <b-form-input type="number"  onkeypress="if(this.value.length>=6) return false;" required v-model="Addbroker.pincode"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="filterPanel mt-4">
                                      <label>Project<span class="text-danger">*</span></label>
                                        <multiselect v-model="Addbroker.project_id" placeholder="Select Project" :searchable="true" label="text" track-by="value" :options="projectList" :taggable="true" ref="project_select" @input="(value)=>updateRequiredField(value,'project_select')"></multiselect>
                                    </b-form-group>
                                      <b-form-group v-if="Addbroker.project_id.value === 319" class=" mt-4">
                                        <label >Enter Building Name</label>
                                        <vue-google-autocomplete
                                          :country="['in']"
                                          :types="'establishment'"
                                          id="autoPl"
                                          v-model="location"
                                          classname="form-control"
                                          placeholder="Start typing"
                                          v-on:placechanged="getAddressData"
                                          >
                                        </vue-google-autocomplete>
                                      </b-form-group>
                                    <b-form-group class=" mt-4">
                                      <label >Rera Status<span class="text-danger">*</span></label>
                                      <b-form-radio-group class="pl-3 mt-3"
                                        v-model="Addbroker.rera_status"
                                        @input="checkCertificate(Addbroker.rera_status)"
                                        :options="[{ id: 'Yes', name: 'Yes' },{ id: 'No', name: 'No' }]"
                                        value-field="id"
                                        text-field="name"
                                      ></b-form-radio-group>
                                    </b-form-group>
                                    <b-form-group v-if="Addbroker.rera_status == 'Yes'">
                                      <label>Rera Id<span class="text-danger">*</span></label>
                                      <b-form-input type="text" required v-model="Addbroker.rera_id"></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if="Addbroker.rera_status == 'Yes'" >
                                      <label>Rera Expiry Date<span class="text-danger">*</span></label>
                                      <b-form-input type="date" :min="minDate" v-model="Addbroker.rera_expiry_date" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group>
                                      <label v-if="Addbroker.rera_status == 'Yes'" for="upload-logo" class="custom-upload-label">
                                        <b-form-file v-model="certificate" accept="application/pdf, application/vnd.ms-excel" required class="mt-3 custom-file-input" @change="uploadCertificate" plain id="upload-logo"></b-form-file>
                                        <div class="mb-2 label-text">Rera Certificate * {{ certificate ? (certificate.type === 'application/pdf' ? certificate.name : '') : '' }}</div>
                                      </label>
                                      <p v-if="Addbroker.rera_status == 'Yes'" class="text-muted image-font text-center mb-0 d-block">Image files type accept .pdf (Maxsize: 5 mb)</p>
                                    </b-form-group>
                                      <b-form-group>
                                        <label>Empanelment Date<span class="text-danger">*</span></label>
                                        <b-form-input type="date" :min="minDate" v-model="Addbroker.empanelment_date" required></b-form-input>
                                    </b-form-group>

                                    <b-form-group>
                                      <label for="upload-sv-form" class="custom-upload-label">
                                        <b-form-file v-model="empanelment_form_scan" accept=".jpg, .jpeg, .pdf" class="mt-3 custom-file-input" @change="uploadSiteVisitForm" plain id="upload-sv-form"></b-form-file>
                                        <div class="mb-2 label-text">Empanelment Form Scan Front {{ empanelment_form_scan ? empanelment_form_scan.name : '' }}</div>
                                      </label>
                                      <p class="text-muted image-font text-center mb-0 d-block">Image files type accept .pdf .jpg .jpeg (Maxsize: 5 mb)</p>
                                    </b-form-group>

                                    <b-form-group>
                                      <label for="upload-sv-form-back" class="custom-upload-label">
                                        <b-form-file v-model="empanelment_form_backside_scan" accept=".jpg, .jpeg, .pdf" class="mt-3 custom-file-input" @change="uploadSiteVisitFormBack" plain id="upload-sv-form-back"></b-form-file>
                                        <div class="mb-2 label-text">Empanelment Form Scan Back {{ empanelment_form_backside_scan ? empanelment_form_backside_scan.name : '' }}</div>
                                      </label>
                                      <p class="text-muted image-font text-center mb-0 d-block">Image files type accept .pdf .jpg .jpeg (Maxsize: 5 mb)</p>
                                    </b-form-group>

                                    <b-form-group class="mt-3">
                                      <label for="upload-logo" class="custom-upload-label">
                                          <b-form-file v-model="visiting_card" class="mt-3 custom-file-input" @change="uploadVisitingCard" plain id="upload-logo"></b-form-file>
                                          <div class="mb-2 label-text">Visiting Card {{ visiting_card ? visiting_card.name : '' }}</div>
                                      </label>
                                      <p class="text-muted image-font text-center mb-0 d-block">Image files type accept .pdf .jpg .jpeg (Maxsize: 5 mb)</p>
                                    </b-form-group>
                                    <b-form-group class="mt-3">
                                      <label for="upload-logo" class="custom-upload-label">
                                          <b-form-file v-model="payment_proof" accept="application/pdf" required class="mt-3 custom-file-input" @change="uploadPaymentProof" plain id="upload-logo"></b-form-file>
                                          <div class="mb-2 label-text">Payment Proof * {{ payment_proof ? payment_proof.name : '' }}</div>
                                      </label>
                                      <p class="text-muted image-font text-center mb-0 d-block">Image files type accept .pdf (Maxsize: 5 mb)</p>
                                    </b-form-group>
                                    <b-form-group class="mt-3">
                                      <label for="upload-logo" class="custom-upload-label">
                                          <b-form-file v-model="pan_card_form_scan" required class="mt-3 custom-file-input" @change="uploadPanCard" plain id="upload-logo"></b-form-file>
                                          <div class="mb-2 label-text">Pancard Upload * {{ pan_card_form_scan ? pan_card_form_scan.name : '' }}</div>
                                      </label>
                                      <p class="text-muted image-font text-center mb-0 d-block">Image files type accept .pdf .png .jpg .jpeg (Maxsize: 5 mb)</p>
                                    </b-form-group>
                                    <b-form-group>
                                        <label>Pancard No<span class="text-danger">*</span></label>
                                        <b-form-input type="text" v-model="Addbroker.pan_card_number" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="filterPanel">
                                      <label>Payment Mode</label>
                                      <multiselect v-model="Addbroker.payment_mode" placeholder="Select Payment Mode" :searchable="true" label="text" track-by="value" :options="paymentModes" :taggable="true"></multiselect>
                                    </b-form-group>
                                    <b-form-group class="mt-4">
                                        <label>Transaction ID/ Cheque No.<span class="text-danger">*</span></label>
                                        <b-form-input type="text" v-model="Addbroker.transaction_id" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group>
                                        <label>About</label>
                                        <b-form-textarea v-model="Addbroker.about" rows="3" max-rows="6">
                                        </b-form-textarea>
                                    </b-form-group>
                                    <b-form-group v-if="!$route.query.tabStatus">
                                      <label>Address<span class="text-danger">*</span></label>
                                        <b-form-textarea v-model="Addbroker.address" required rows="3" max-rows="6">
                                        </b-form-textarea>
                                    </b-form-group>
                                    <b-form-group class="pl-3 checkbox-fix">
                                      <b-form-checkbox
                                        v-model="Addbroker.is_affiliate"
                                        name="Active ?"
                                        value="true"
                                        unchecked-value="false"
                                      >
                                        Is Affiliate ?
                                      </b-form-checkbox>
                                    </b-form-group>
                                    <b-form-group class="mt-5">
                                        <b-button type="submit" variant="primary" block>Submit</b-button>
                                    </b-form-group>
                                  </b-form>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {decodeBrokerParams} from '@/assets/scripts/utility.js';
import moment from 'moment';
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect,
    VueGoogleAutocomplete
  },
  name: 'New',
  data() {
    return {
      localityList: [],
      projectList: [],
      certificate: null,
      visiting_card: null,
      payment_proof: null,
      pan_card_form_scan: null,
      empanelment_form_scan: null,
      empanelment_form_backside_scan: null,
      location: null,
      regionList: [],
      Addbroker: {
        name: null,
        email: null,
        contact_number: null,
        firm_name: null,
        transaction_id:null,
        dob: null,
        pincode: null,
        primary_locality_id: null,
        about: null,
        project_id: {},
        rera_status: 'No',
        rera_certificate_scan: null,
        empanelment_date: this.$moment().format("YYYY-MM-DD"),
        rera_expiry_date: null,
        empanelment_form_scan: null,
        empanelment_form_backside_scan: null,
        payment_proof: null,
        pan_card_form_scan: null,
        pan_card_number: null,
        rera_id: null,
        region: [],
        address: null,
        location: null,
        latitude: null,
        longitude: null,
        city_id: null,
        visiting_card: null,
        is_affiliate: null,
        payment_mode: null,
        iso_code: "+91",
      },
    }
  },
  mounted(){
    if (this.$route.query.data) {
      const updated = { ...this.Addbroker, ...this.$route.query.data };
      this.Addbroker = updated
    }
    this.countryList.filter((country)=>{
      if(country.value === "+91") {
        this.Addbroker.iso_code = country;
      }
    })
    this.updateRequiredField('','city_select');
    this.updateRequiredField('','region_select');
    this.updateRequiredField('','locality_select');
    this.updateRequiredField('','project_select');
  },
  methods: {
    uploadCertificate() {
      if (event.target.files[0].type === 'application/pdf') {
        this.Addbroker.rera_certificate_scan = event.target.files[0];
      } else {
        swal({
          title: "Accept Only Pdf",
          icon: "error",
        });
      }
    },
    uploadSiteVisitForm() {
      this.Addbroker.empanelment_form_scan = event.target.files[0];
    },
    uploadSiteVisitFormBack() {
      this.Addbroker.empanelment_form_backside_scan = event.target.files[0];
    },
    uploadVisitingCard() {
      this.Addbroker.visiting_card = event.target.files[0];
    },
    uploadPaymentProof() {
      this.Addbroker.payment_proof = event.target.files[0];
    },
    uploadPanCard() {
      this.Addbroker.pan_card_form_scan = event.target.files[0];
    },
    checkCertificate(item) {
      if (item == 'No') {
        this.Addbroker.rera_certificate_scan = null,
        this.Addbroker.rera_id = null
      }
    },
    updateRequiredField(value,ref,isMultiple){
      let hasValue = false;
      if(isMultiple){
        hasValue = value && value.length > 0 && value.filter(item => !!item.value).length > 0;
      }else{
        hasValue = value && !!value.value;
      }
      this.$nextTick(()=> {
        if(this.$refs[ref]){
          this.$refs[ref].$el.querySelector('input').required = !hasValue;
        }
      });
    },
    getAddressData(data, anotherData){
      this.Addbroker.location = anotherData.name
      this.Addbroker.latitude = data.latitude
      this.Addbroker.longitude = data.longitude
    },
    fetchRegion(row) {
      let queryP = {}
      this.updateRequiredField(row,'city_select');
      if(!row || !row.value){
        return;
      }
      queryP['city_id'] = row.value
      this.$axios.get(`brokers/${row.value}/regions`, { params: queryP }).then((res) => {
        this.regionList = []
        res.data.regions_data.forEach((item,index) => {
          this.regionList.push({value: item.id, text: item.name})
        });
      });
    },
    fetchLocality() {
      this.updateRequiredField(this.Addbroker.region,'region_select',true);
      let queryP = {}
      let {length : l, [l - 1] : b} = this.Addbroker.region;
      if (this.Addbroker.region.length > 0) {
        queryP['region_id'] = b.value
        this.$axios.get(`brokers/${b.value}/localities`, { params: queryP }).then((res) => {
          this.localityList = []
          res.data.localities.forEach((item,index) => {
            this.localityList.push({value: item.id, text: item.name})
          });
          this.localityList.unshift({value: null, text: 'Select Locality', disabled: true})
        });
      }
    },
    fetchProject(row) {
      let queryP = {}
      this.updateRequiredField(row,'locality_select');
      if(!row || !row.value){
        return;
      }
      queryP['locality_id'] = row.value
      this.$axios.get(`brokers/${row.value}/projects`, { params: queryP }).then((res) => {
        this.projectList = []
        res.data.projects.forEach((item,index) => {
          this.projectList.push({value: item.id, text: item.name})
        });
        this.projectList.push({value: 319, text: 'Add New Building'})
        this.projectList.unshift({value: null, text: 'Select Project', disabled: true})
      });
    },
    AddNewbroker() {
      let apiParams = decodeBrokerParams(this.Addbroker);
      let formData = new FormData();
      if (apiParams.rera_certificate_scan) {
        formData.append('rera_certificate_scan', apiParams.rera_certificate_scan);
      }
      if (apiParams.empanelment_form_scan) {
        formData.append('empanelment_form_scan', apiParams.empanelment_form_scan);
      }
      if (apiParams.empanelment_form_backside_scan) {
        formData.append('empanelment_form_backside_scan', apiParams.empanelment_form_backside_scan);
      }
      if (apiParams.visiting_card) {
        formData.append('visiting_card', apiParams.visiting_card);
      }
      if (apiParams.payment_proof) {
        formData.append('receipt_scan', apiParams.payment_proof);
      }
      if (apiParams.pan_card_form_scan) {
        formData.append('pan_card_form_scan', apiParams.pan_card_form_scan);
      }
      if (apiParams.location) {
        formData.append('location', apiParams.location);
        formData.append('latitude', apiParams.latitude);
        formData.append('longitude', apiParams.longitude);
      }
      formData.append('name', apiParams.name);
      formData.append('email', apiParams.email);
      formData.append('contact_number', apiParams.contact_number);
      formData.append('firm_name', apiParams.firm_name);
      formData.append('transaction_id',apiParams.transaction_id);
      formData.append('city_id', apiParams.city_id);
      formData.append('iso_code', apiParams.iso_code);
      formData.append('primary_locality_id', apiParams.primary_locality_id);
      formData.append('about', apiParams.about);
      formData.append('project_id', apiParams.project_id);
      formData.append('rera_status', apiParams.rera_status);
      formData.append('empanelment_date', apiParams.empanelment_date);
      formData.append('rera_expiry_date', apiParams.rera_expiry_date);
      formData.append('is_affiliate', apiParams.is_affiliate);
      formData.append('dob', apiParams.dob);
      formData.append('pincode', apiParams.pincode);
      formData.append('pan_card_number', apiParams.pan_card_number);
      if(apiParams.payment_mode){
        formData.append('payment_mode', apiParams.payment_mode);
      }
      
      formData.append('can_send_notification', true);
      if (apiParams.rera_id) {
        formData.append('rera_id', apiParams.rera_id);
      }
      if (this.$route.query.tabStatus) {
        formData.append('prospect_cp_id', apiParams.id);
      }
      formData.append('address', apiParams.address);
      apiParams.broker_regions_attributes.forEach((item,index) => {
        formData.append(`broker_regions_attributes[${index}][region_id]`, item.region_id);
        if (index == apiParams.broker_regions_attributes.length -1) {
          formData.append(`broker_regions_attributes[${index}][is_primary]`, true);
        } else {
          formData.append(`broker_regions_attributes[${index}][is_primary]`, false);
        }
        formData.append(`broker_regions_attributes[${index}][_destroy]`, false);
      })
      this.$axios
        .post("brokers", formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          swal({
            title: "Created!",
            icon: "success",
          }).then(this.$router.replace({ name: "Home" }));
        })
        .catch((err) => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error",
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
            });
          }
        });
      }
  },
  computed: {
    minDate() {
      return this.$moment().format("YYYY-MM-DD")
    },
    cityList() {
      return this.$store.getters.cityMasterList;
    },
    countryList() {
      return this.$store.getters.countryList;
    },
    paymentModes() {
      return this.$store.getters.paymentModes;
    },
  },
  watch: {
    '$store.getters.countryList': {
      deep: true,
      handler(newVal) {
        this.Addbroker.iso_code = newVal[0];
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.image-font {
  font-size: 15px;
}
.iso-code-section {
  display:flex;
  gap: 10px;
}
.city-id-section {
  flex: 1;
}
</style>