export function paramsFromQuery(mainParams, queryParams){
  let search_query = queryParams.search_query
  let page = queryParams.pageNo
  let as = queryParams.as
  let is_advanced_search = queryParams.is_advanced_search
  let sortKey = queryParams.sortKey;
  let sortDir = queryParams.sortDir;
  if(search_query){
    // mainParams['as'] = true
    mainParams['search_query'] = search_query;
  }
  if(page){
    mainParams['page'] = page
  }
  if(as){
    mainParams['as'] = true
  }
  if(is_advanced_search){
    mainParams['is_advanced_search'] = true
  }
  if(sortKey){
    mainParams['key'] = decodeSortKey(sortKey)
    mainParams['sort'] = sortDir || 'asc'
  }
}
export function decodeBrokerParams(broker){
  let senddata = {}
  let regions = []
  senddata['id'] = broker.id
  senddata['name'] = broker.name
  senddata['email'] = broker.email
  senddata['contact_number'] = broker.contact_number
  senddata['firm_name'] = broker.firm_name
  senddata['onboarding_confirmed'] = broker.onboarding_confirmed
  senddata['transaction_id'] = broker.transaction_id
  senddata['dob'] = broker.dob
  if (broker.city_id) {
    senddata['city_id'] = broker.city_id.value
  }
  if (broker.iso_code) {
    senddata['iso_code'] = broker.iso_code.value
  }
  
  if (broker.primary_locality_id) {
    senddata['primary_locality_id'] = broker.primary_locality_id.value
  }
  if (broker.project_id) {
    senddata['project_id'] = broker.project_id.value
  }
  senddata['rera_status'] = broker.rera_status
  senddata['rera_id'] = broker.rera_id
  senddata['about'] = broker.about
  senddata['address'] = broker.address
  senddata['rera_certificate_scan'] = broker.rera_certificate_scan
  senddata['rera_expiry_date'] = broker.rera_expiry_date
  senddata['empanelment_date'] = broker.empanelment_date
  senddata['empanelment_form_number'] = broker.empanelment_form_number
  senddata['empanelment_form_scan'] = broker.empanelment_form_scan
  senddata['empanelment_form_backside_scan'] = broker.empanelment_form_backside_scan
  senddata['visiting_card'] = broker.visiting_card
  senddata['location'] = broker.location
  senddata['latitude'] = broker.latitude
  senddata['longitude'] = broker.longitude
  senddata['is_affiliate'] = broker.is_affiliate
  senddata['payment_proof'] = broker.payment_proof
  senddata['pan_card_form_scan'] = broker.pan_card_form_scan
  senddata['pan_card_number'] = broker.pan_card_number
  senddata['pincode'] = broker.pincode
  if (broker.payment_mode){
    senddata['payment_mode'] = broker.payment_mode.value
  }
  broker.region.forEach(reg => {
    let regionattribute = {}
    regionattribute['id'] = reg.id
    regionattribute['region_id'] = reg.value
    regions.push(regionattribute);
  });
  senddata['broker_regions_attributes'] = regions
  return senddata;
}

export function decodeInvitationBrokerParams(broker){
  let senddata = {}
  let regions = []
  senddata['name'] = broker.name
  senddata['email'] = broker.email
  senddata['contact_number'] = broker.contact_number
  senddata['firm_name'] = broker.firm_name
  senddata['city_id'] = broker.city_id
  senddata['primary_locality_id'] = broker.primary_locality_id
  senddata['rera_id'] = broker.rera_id
  senddata['about'] = broker.about
  senddata['address'] = broker.address
  broker.region.forEach((reg,index) => {
    let regionattribute = {}
    if (index == broker.region.length -1) {
      regionattribute['is_primary'] = true
    } else {
      regionattribute['is_primary'] = false
    }
    regionattribute['region_id'] = reg.value
    regions.push(regionattribute);
  });
  senddata['broker_regions_attributes'] = regions
  return senddata;
}

export function prepareBrokerSearchParams(advSParams){
  let toSend = {}
    toSend['name'] = advSParams.name
    toSend['firm_name'] = advSParams.firm_name
    toSend['contact_number'] = advSParams.contact_number
    toSend['email'] = advSParams.email
    toSend['rera_id']= advSParams.rera_id
    toSend['referer_broker']= advSParams.referer_broker
    toSend['is_affiliate']= advSParams.is_affiliate
    if (advSParams.executive_id) {
      toSend['executive_id']= advSParams.executive_id.value
    }
    toSend['empanelment_date_from']= advSParams.empanelment_date_from
    toSend['empanelment_date_upto']= advSParams.empanelment_date_upto
    if (advSParams.rm_ids) {
      toSend['rm_ids'] = advSParams.rm_ids.filter(i => i!== null).map(i => i.value)
    }
    if (advSParams.localities) {
      toSend['localities'] = advSParams.localities.filter(i => i!== null).map(i => i.value)
    }
    toSend["rera_status"] = advSParams.rera_status
  return toSend;
}
export function decodeSortKey(key){
  if(key === "ncd"){
    return "next_call_date";
  }
  return key;
}